import Header from'./Components/Header'
import Footer from'./Components/Footer'
import Home from './Pages/Home'
import ChairmanWelcome from './Pages/ChairmanWelcome'
import DirectorWelcome from './Pages/DirectorWelcome'
import PrincipalWelcome from './Pages/PrincipalWelcome'
import GettingStartedatLFIS from './Pages/GettingStartedatLFIS'
import AdmissionProcess from './Pages/AdmissionProcess'
import ComputerLab from './Pages/ComputerLab'
import Sports from './Pages/Sports'
import EarlyLearningAges from './Pages/EarlyLearningAges'
import PrimaryAges from './Pages/PrimaryAges'
import MiddleAges from './Pages/MiddleAges'
import ParentGuidelines from './Pages/ParentGuidelines'
import SchoolERPSoftware from './Pages/SchoolERPSoftware'
import Gallery from'./Components/Gallery'
import SubGallery from'./Pages/SubGallery'
import VideoGallery from'./Pages/VideoGallery'
import NewsArticle from'./Components/NewsArticle'
import Facilities from'./Pages/Facilities'
import ContactUs from './Pages/ContactUs'
import ThankYou from './Pages/ThankYou'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'lightbox.js-react/dist/index.css'
import { BrowserRouter, Route, Routes,  } from 'react-router-dom';
import './Assets/css/Style.css';

function App() {
  return (
   <>
    <BrowserRouter>
      <Header/>
       <Routes>
       <Route path='/' element= {<Home/>}/>
       <Route path='ChairmanWelcome' element= {<ChairmanWelcome/>}/>
       <Route path='DirectorWelcome' element= {<DirectorWelcome/>}/>
       <Route path='PrincipalWelcome' element= {<PrincipalWelcome/>}/>
       <Route path='GettingStartedatLFIS' element= {<GettingStartedatLFIS/>}/>
       <Route path='AdmissionProcess' element= {<AdmissionProcess/>}/>
       <Route path='ComputerLab' element= {<ComputerLab/>}/>
       <Route path='Sports' element= {<Sports/>}/>
       <Route path='EarlyLearningAges' element= {<EarlyLearningAges/>}/>
       <Route path='PrimaryAges' element= {<PrimaryAges/>}/>
       <Route path='MiddleAges' element= {<MiddleAges/>}/>
       <Route path='ParentGuidelines' element= {<ParentGuidelines/>}/>
       <Route path='SchoolERPSoftware' element= {<SchoolERPSoftware/>}/>
       <Route path='Gallery' element= {<Gallery/>}/>
       <Route path='SubGallery' element= {<SubGallery/>}/>
       <Route path='VideoGallery' element= {<VideoGallery/>}/>
       <Route path='NewsArticle' element= {<NewsArticle/>}/>
       <Route path='Facilities' element= {<Facilities/>}/>
       <Route path='ContactUs' element= {<ContactUs/>}/>
       <Route path='ThankYou' element= {<ThankYou/>}/>
       </Routes>
      <Footer/>
    </BrowserRouter>
   </>
  );
}

export default App;
