import React, {useState, useEffect} from 'react' 
import { getGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import LazyLoad from 'react-lazyload';
const HomeGallery = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getGallery();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);



     var settings = {
             loop: true,
              margin:10,
              responsiveClass: true,
              autoplay:true,
             dots:true,
              items:5,
              responsive: {
                0: {
                  items: 1,
                },
                567: {
                  items: 2,
                },
                768: {
                  items: 2,
                },
                992: {
                  items:3,
                },
                1200: {
                  items:4,
                },
                 1600: {
                  items:5,
                }
              }
    };
       return (
      <>
      <div className="gallery">
      <h5 className="mainheading"> Discover <b>Our Gallery</b></h5>
      <div className="row">
            <div className="col-lg-12">
            {loading ? (
                  <p>Loading gallery...</p>
                ) : (
                 

                   
              <OwlCarousel className='owl-theme galleryslider wow animate__animated animate__slideInUp' loop margin={10}  {...settings}>
              {data.map((item, index) => (
                     <div className="item">
                          <div className="galleryimg">
                                 <div className="galleryimgbox">
                                 <LazyLoad>    <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid" alt=" Lance Fort International School"/></LazyLoad>
                                      <div className="gallerydesc">
                                         <p>{item.title}</p>
                                         <Link to="Gallery" className=""><i className="bi bi-arrow-right"></i></Link>
                                      </div>
                                 </div>
                          </div>
                     </div>
                      ))}
                     {/* <div className="item">
                          <div className="galleryimg">
                                 <div className="galleryimgbox">
                                    <img src="https://webapi.entab.info/api/image/LFIS/public/Images/gallery2.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                                      <div className="gallerydesc">
                                         <p>Gallery Title</p>
                                          <Link to="Gallery" className=""><i className="bi bi-arrow-right"></i></Link>
                                      </div>
                                 </div>
                          </div>
                     </div>
                     <div className="item">
                          <div className="galleryimg">
                                 <div className="galleryimgbox">
                                    <img src="https://webapi.entab.info/api/image/LFIS/public/Images/gallery4.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                                      <div className="gallerydesc">
                                         <p>Gallery Title</p>
                                          <Link to="Gallery" className=""><i className="bi bi-arrow-right"></i></Link>
                                      </div>
                                 </div>
                          </div>
                     </div>
                      <div className="item">
                          <div className="galleryimg">
                                 <div className="galleryimgbox">
                                    <img src="https://webapi.entab.info/api/image/LFIS/public/Images/gallery5.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                                      <div className="gallerydesc">
                                         <p>Gallery Title</p>
                                          <Link to="Gallery" className=""><i className="bi bi-arrow-right"></i></Link>
                                      </div>
                                 </div>
                          </div>
                     </div>
                      <div className="item">
                          <div className="galleryimg">
                                 <div className="galleryimgbox">
                                    <img src="https://webapi.entab.info/api/image/LFIS/public/Images/gallery6.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                                      <div className="gallerydesc">
                                         <p>Gallery Title</p>
                                          <Link to="Gallery" className=""><i className="bi bi-arrow-right"></i></Link>
                                      </div>
                                 </div>
                          </div>
                     </div> */}
              </OwlCarousel>
              )}
            </div>
      </div>
   </div>
      </>
  )
}

export default HomeGallery
