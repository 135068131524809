import React from 'react'
import { Link } from 'react-router-dom'
const PrimaryAges = () => {
  return (
      <>
      <div className="innerslide">
      <h3>Primary Ages | 6-11</h3>
      <ul className="braedcrumb">
          <li><Link to="/">Home</Link></li>
          <li>Learning</li>
          <li>Primary Ages | 6-11</li>
      </ul>
</div>
<div className="innersec">
  <div className="container">
          <div className="row">
              <div className="col-lg-12">
                  <h4 className="grdxt">Wisdom Begins In Wonder</h4>
                      <p>The Primary School at the <b> LFIS</b> has been a thriving learning community where children and their families experience a sense of belonging through the joy of working, playing, and learning together. </p>
<p>Our philosophies and curriculum are developed within the Primary Years Programme <b>(PYP)</b> framework. From Kindergarten to Grade 5, we view each child as the chief architect of their own learning, with teachers and assistants acting as facilitators. We consider every child to be a competent researcher and our teachers support their curiosity about the world around them. Students are challenged to be inquirers who ask questions, propose hypotheses, and conduct investigations and research to find answers.</p>
<p>Our programme is enriched by consistent visits to our Garden of Wonders and Library, playtime in their private playground, and attendance at and active participation in whole school activities and school productions. To learn how LFIS can benefit your family, please explore the <b>‘Primary School in Depth’</b> section below or <b><span>contact our admissions</span></b> team for a personal consultation.</p>


    <h5 className="mid-head">Primary School In Depth</h5>
        <p className="smallhead">Curriculum</p>
              <p><b>LFIS</b> is  committed to the rigorously framed & deisgned Lance Fort Research & Development based Primary Years Programme <b>(PYP)</b> Curriculum, meaning that our teaching is balanced, concept-based, and planned around our students. We believe academic learning is inextricably intertwined with social and emotional development. Just as our school’s notion states: <b><span>“Wisdom Begins in Wonder.”</span></b> </p>
<p>We recognise that every child is an individual, so we provide opportunities for children to explore and express themselves through multiple pathways. Students learn in different ways and we appreciate the need to personalise learning to best meet their needs. Therefore, LFIS has a <b><span>comprehensive student support services team</span></b> to support our youngest learners.</p>
<p>Our rich curricular programme gives children a strong foundation in Mathematics, Social Studies, Drama, Languages, Music, Visual Arts, Science, Personal and Social Education, and Physical Education. Students have their homeroom teachers for their core subjects and specialist teachers for Languages, the Arts, Technology, Physical Education, and the Library. We provide students the skills and knowledge necessary for thriving in Middle School, whether they continue here, in their home country, or abroad.</p>
              </div>
           </div>
      </div>
  </div>
      </>
  )
}

export default PrimaryAges
