import React from 'react'
import { Link } from 'react-router-dom'
const MiddleAges = () => {
  return (
      <>
      <div className="innerslide">
      <h3>Middle | Ages 11-14 </h3>
      <ul className="braedcrumb">
          <li><Link to="/">Home</Link></li>
          <li>Learning</li>
          <li>Middle | Ages 11-14 </li>
      </ul>
</div>
<div className="innersec">
  <div className="container">
          <div className="row">
              <div className="col-lg-12">
                  <h4 className="grdxt"> A Gateway for Knowledge & Wisdom </h4>
                     <p>Grades 6-8 at the <b>LFIS</b> have been a vibrant, student-centered community. Following the rigorously framed & deisgned Lance Fort Research & Development based Middle Years Programme <b>(MYP)</b> Curriculum, our programme meets the unique needs of young adolescents as they develop the knowledge, understanding, attitudes, and skills necessary to participate actively and responsibly in a changing world. Just as our school’s notion states: <b><span>“Wisdom Begins in Wonder.”</span></b> </p>

<p>Grades 6 – 8 are a crucial time of development for students. They are building, reflecting, and strengthening the attitudes, values, and dispositions that will shape and guide the adults they will become. We are eager to support them in building the skills they need for their overall sense of wellbeing and future success. Most students seek more independence during these years, so our school environment provides them with opportunities to have more control over their experience, preparing them for the more complex life choices they will face.</p>

<p>Our programme is enriched by visits to our Garden of wonders and Library, local & National excursions, and active participation in whole school activities and productions. To learn how <b>LFIS</b> can benefit your family, please explore the ‘Middle School in Depth’ section below or <b><span>contact our admissions team</span></b> for a personal consultation.</p>

    <h5 className="mid-head">Primary School In Depth</h5>
        <p className="smallhead">Curriculum</p>
          <p>The LFIS Middle Years Programme <b>(MYP)</b> is the framework we use to provide an inclusive and challenging curriculum to students aged 11 to 16. This international curriculum helps students develop the knowledge, understanding, attitudes, and skills necessary to participate actively and responsibly in a changing world. We ensure that all of our students have the skills and knowledge necessary for thriving academically, whether they continue here, in their home country, or abroad.</p>

<p>Our students learn through inquiry, action, and reflection across subject groups connected through key concepts and global contexts. As a result, they develop skills that empower them to manage their learning, meet the objectives of the different subjects, and build a strong foundation for the future. In addition, they benefit from the following subject areas: English, Mathematics, Integrated Science (Biology, Chemistry, and Physics), History, Geography, Visual Arts, Drama, Music, Design, and Physical and Health Education.
</p>
<p>LFIS is also a haven rich in the Arts, Athletics & Activities . Our campus is nestled sophistically & it’s a place that provides further opportunity for students to explore concepts linked to the natural environment and to reflect on their responsibility for its sustainability. Whether engaging in after school programmes, organized athletic events, or musical and dramatic performances, our students thrive on elevated opportunities to do, learn and experience more.</p>
        </div>
           </div>
      </div>
  </div>
      </>
  )
}

export default MiddleAges
