import React from 'react' 
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';

const ThankYou = () => {
  return (
    <>
                <div className="innerslide">
                <h3>Thank You</h3>
                <ul className="braedcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Thank You</li>
                </ul>
            </div>
            <div className="innersec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Link to="/">
                                <LazyLoad>  <img src='https://webapi.entab.info/api/image/LFIS/public/Images/thankupage.png' className="img-fluid mx-auto d-block" alt="Lance Fort International School" /></LazyLoad></Link>
                            </div>
                        </div>
                    </div>
            </div>
    </>
  )
}

export default ThankYou
