import React from 'react'
import MidSecInfoprop from'../Components/MidSecInfoprop' 
const MidSecInfo = () => {
  return (
    <>
       <MidSecInfoprop title="About Us" image="https://webapi.entab.info/api/image/LFIS/public/Images/aboutusicn.png"/>
       <MidSecInfoprop title="Management" image="https://webapi.entab.info/api/image/LFIS/public/Images/Managementicn.png"/>
       <MidSecInfoprop title="facilities" image="https://webapi.entab.info/api/image/LFIS/public/Images/facilitiesicn.png"/>
       <MidSecInfoprop title="sports" image="https://webapi.entab.info/api/image/LFIS/public/Images/sportsicn.png"/>
       <MidSecInfoprop title="Enrichment programs" image="https://webapi.entab.info/api/image/LFIS/public/Images/Enrichmentprogramsicn.png"/>
    </>
  )
}

export default MidSecInfo
