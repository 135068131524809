import React from 'react'
import LazyLoad from 'react-lazyload';

const SkilingInfoprops = (props) => {
  return (
    <>
       <div className="skillingcirclebox wow animate__animated animate__fadeInLeft">
          <div className="skillingcircleboximg">
          <LazyLoad>      <img src={props.image} className="img-fluid" alt="Lance Fort International School "/></LazyLoad>
            </div>
            <p>{props.title}</p>
        </div>
    </>
  )
}

export default SkilingInfoprops
