import React from 'react' 
import { SlideshowLightbox } from 'lightbox.js-react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Sports = () => {
  return (
    <>
    <div className="innerslide">
    <h3>Sports</h3>
    <ul className="braedcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Infrastructure</li>
        <li>Sports</li>
    </ul>
        </div>
        <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <LazyLoad>    <SlideshowLightbox><img src='https://webapi.entab.info/api/image/LFIS/public/Images/sport1.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox></LazyLoad>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <LazyLoad>    <SlideshowLightbox><img src='https://webapi.entab.info/api/image/LFIS/public/Images/sport2.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox></LazyLoad>
                        </div>
                </div>
                <div className="col-lg-4 col-md-6">
                        <div className="laboratoriesimg">
                        <LazyLoad>   <SlideshowLightbox><img src='https://webapi.entab.info/api/image/LFIS/public/Images/sport3.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox></LazyLoad>
                        </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Sports
