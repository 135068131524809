import React, { useEffect, useState } from 'react'
// import article1 from'https://webapi.entab.info/api/image/LFIS/public/Images/article1.jpg'
// import article2 from'https://webapi.entab.info/api/image/LFIS/public/Images/article2.jpg'
// import article3 from'https://webapi.entab.info/api/image/LFIS/public/Images/article3.jpg'

import { getNews } from '../Service/Api';
import { Link } from 'react-router-dom' 
import OwlCarousel from 'react-owl-carousel';
import LazyLoad from 'react-lazyload';
const HomeNews = () => {
   const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getNews();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);
  
    var settings = {
        loop: true,
        margin:0,
        responsiveClass: true,
        autoplay:true,
       dots:true,
        items:3,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
          992: {
            items:3,
            margin:0
          },
           1600: {
            items:3,
            margin:0
          }
        }
  };
  return (
    <>
      
    <div className="homenews">
      <h5 className="mainheading">
         Recent <b>News & Articles</b>
      </h5>
      <div className="container">
         <div className="row ">
            <div className="col-lg-12">
            <OwlCarousel className='owl-theme newsslide wow animate__animated animate__slideInUp' loop margin={10} {...settings}>
            {data.map((item, index) => (
               <div className="item wow animate__animated animate__fadeInLeft"  key={index}>
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                     <LazyLoad>   <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid" alt="Lance Fort International School "/></LazyLoad>
                        <div className="homenewsboxtitle"><p>{item.title}</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                              <p>{item.description}</p>                                
                        <div className="homenewsboxbottom">
                        <span><i className="bi bi-calendar2-event"></i> {item.date}</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div>
               ))}
               {/* <div className="item wow animate__animated animate__slideInUp">
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                        <img src="https://webapi.entab.info/api/image/LFIS/public/Images/article1.jpg" className="img-fluid" alt="Lance Fort International School "/>
                        <div className="homenewsboxtitle"><p>LFIS Annual Day Celebrations(2022-23)</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                              <p>Lorem ipsum dolor sit amet consectetur. Tortor ullamcorper a metus blandit volutpat interdum molestie pretium. Leo enim interdum purus lacinia donec vulputate et facilisis. Id morbi accumsan aliquet arcu gravida erat. Elit ipsum commodo ullamcorper malesuada a adipiscing.</p>
                        
                        <div className="homenewsboxbottom">
                           <span><i className="bi bi-calendar2-event"></i> 08/Dec/2023</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="item wow animate__animated animate__fadeInRight">
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                        <img src="https://webapi.entab.info/api/image/LFIS/public/Images/article3.jpg" className="img-fluid" alt="Lance Fort International School "/>
                        <div className="homenewsboxtitle"><p>Team Building Games and activities for Kids</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                              <p>Lorem ipsum dolor sit amet consectetur. Tortor ullamcorper a metus blandit volutpat interdum molestie pretium. Leo enim interdum purus lacinia donec vulputate et facilisis. Id morbi accumsan aliquet arcu gravida erat. Elit ipsum commodo ullamcorper malesuada a adipiscing.</p>
                        
                        <div className="homenewsboxbottom">
                           <span><i className="bi bi-calendar2-event"></i> 08 Dec 2023</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="item wow animate__animated animate__fadeInLeft">
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                        <img src="https://webapi.entab.info/api/image/LFIS/public/Images/article1.jpg" className="img-fluid" alt="Lance Fort International School "/>
                        <div className="homenewsboxtitle"><p>Pratapgarh Farm Excursion</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                              <p>Lorem ipsum dolor sit amet consectetur. Tortor ullamcorper a metus blandit volutpat interdum molestie pretium. Leo enim interdum purus lacinia donec vulputate et facilisis. Id morbi accumsan aliquet arcu gravida erat. Elit ipsum commodo ullamcorper malesuada a adipiscing.</p>
                        
                        <div className="homenewsboxbottom">
                        <span><i className="bi bi-calendar2-event"></i> 08 Dec 2023</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="item wow animate__animated animate__slideInUp">
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                        <img src="https://webapi.entab.info/api/image/LFIS/public/Images/article2.jpg" className="img-fluid" alt="Lance Fort International School "/>
                        <div className="homenewsboxtitle"><p>LFIS Annual Day Celebrations(2022-23)</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                              <p>Lorem ipsum dolor sit amet consectetur. Tortor ullamcorper a metus blandit volutpat interdum molestie pretium. Leo enim interdum purus lacinia donec vulputate et facilisis. Id morbi accumsan aliquet arcu gravida erat. Elit ipsum commodo ullamcorper malesuada a adipiscing.</p>
                        
                        <div className="homenewsboxbottom">
                           <span><i className="bi bi-calendar2-event"></i> 08 Dec 2023</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="item wow animate__animated animate__fadeInRight">
                  <div className="homenewsbox">
                     <div className="homenewsboximg">
                        <img src="https://webapi.entab.info/api/image/LFIS/public/Images/article3.jpg" className="img-fluid" alt="Lance Fort International School "/>
                        <div className="homenewsboxtitle"><p>Team Building Games and activities for Kids</p></div>
                     </div>
                     <div className="homenewsboxdesc">
                        <p>Lorem ipsum dolor sit amet consectetur. Tortor ullamcorper a metus blandit volutpat interdum molestie pretium. Leo enim interdum purus lacinia donec vulputate et facilisis. Id morbi accumsan aliquet arcu gravida erat. Elit ipsum commodo ullamcorper malesuada a adipiscing.</p>                        
                        <div className="homenewsboxbottom">
                           <span><i className="bi bi-calendar2-event"></i> 08 Dec 2023</span>
                           <Link to="NewsArticle">Read More</Link>
                        </div>
                     </div>
                  </div>
               </div> */}
            </OwlCarousel>
            </div>
         </div>
      </div>
</div>
    </>
  )
}

export default HomeNews
