import React from 'react'            
import HomeGallery from'../Components/HomeGallery'
import SkillingSlider from'../Components/SkillingSlider'     
import HomeNews from'../Components/HomeNews'
import { Link } from 'react-router-dom';
import SkilingInfo from'../Components/SkilingInfo'
import MidSecInfo from'../Components/MidSecInfo' 
import Highlights from'../Components/Highlights'
import PopupModal from'../Components/PopupModal'
import OnlineReg from'../Components/OnlineReg'
import LazyLoad from 'react-lazyload';
const Home = () => {
  return (
     <> 
     <PopupModal></PopupModal>
     <Highlights></Highlights>
    <div className="campus-logo">
        <Link href="https://lancefort.campuscare.cloud/" target="_blank">
        <LazyLoad>  <img src='https://webapi.entab.info/api/image/LFIS/public/Images/Campulogo.png' alt="Lance Fort International School"/></LazyLoad>
          </Link>
    </div>
    <div className="OnlineR">
      <OnlineReg />
        {/* <span className="bounce1">
        <Link href="#" target="_blank" className="parantlogo ">  Online Registration  </Link>
        <Link href="#" target="_blank" className=""> <i className="bi bi-paperclip"></i></Link>
        </span> */}
    </div>
      <div id="homeslider">
          <ul id="slides">
              <li className="slide active" style={{zIndex : '1'}}>
              <div className="slide-partial slide-left">
               <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left1.jpg'/>
              </div>
              <div className="slide-partial slide-right">
               <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right1.jpg'/>
              </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
              <li className="slide" style={{zIndex : '0'}}>
              <div className="slide-partial slide-left">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left2.jpg'/>
                 </div>
              <div className="slide-partial slide-right">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right2.jpg'/>
                 </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
              <li className="slide" style={{zIndex : '0'}}>
              <div className="slide-partial slide-left">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left3.jpg'/>
                 </div>
              <div className="slide-partial slide-right">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right3.jpg'/>
                 </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
              <li className="slide" style={{zIndex :' 0'}}>
              <div className="slide-partial slide-left">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left4.jpg'/>
                 </div>
              <div className="slide-partial slide-right">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right4.jpg'/>
                 </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
              <li className="slide" style={{zIndex :' 0'}}>
              <div className="slide-partial slide-left">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left5.jpg'/>
                 </div>
              <div className="slide-partial slide-right">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right5.jpg'/>
                 </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
              <li className="slide" style={{zIndex : '0'}}>
              <div className="slide-partial slide-left">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-left6.jpg'/>
                 </div>
              <div className="slide-partial slide-right">
                  <img alt="img" src='https://webapi.entab.info/api/image/LFIS/public/Images/slide-right6.jpg'/>
                 </div>
              <h1 className="title"><span className="title-text">Lance Fort International School</span></h1>
              </li>
          </ul>
            <ul id="slide-select">
                <li className="btn prev">&lt;</li>
                <li className="selector current"></li>
                <li className="selector"></li>
                <li className="selector"></li>
                <li className="selector"></li>
                <li className="selector"></li>
                <li className="btn next">&gt;</li>
            </ul>
      </div> 
      <div className="excellencediv">
          <p className="typing"><span>Driven by passion for excellence</span></p>
            <Link to="#" className="mainbtnw">ADMISSION <span><i className="bi bi-chevron-double-right"></i></span></Link>
      </div>
      <div className="homeabout">
     <h5 className="mainheading">Lance Fort <b>International School</b></h5>
                <p>The Temple of Wisdom Welcomes you</p>
     <div className="container"> 
        <div className="row">
             <div className="col-lg-7  wow animate__animated animate__fadeInLeft">
                  <div className="homeaboutinfo">
                      <div> Children learn more through actions than instructions, and that’s why here at Lance Fort International School, we focus on experiential learning. Our highly educated, thoughtful, and diligent teachers follow innovative pedagogical endeavours to equip learners with the requisite skills. We nurture them to become responsible citizens with distinctive personalities and contribute their best in a rapidly changing society. </div>
               
                  </div>
               <div className="homeaboutbtn">
                  <Link to="#" className="mainbtnw">Read More <span><i className="bi bi-chevron-double-right"></i></span></Link>
               </div>
             </div>
             <div className="col-lg-5">
                <div className="homeaboutimg  wow animate__animated animate__fadeInRight">
                <LazyLoad>     <img src='https://webapi.entab.info/api/image/LFIS/public/Images/about.jpg' className="img-fluid"/></LazyLoad>
                      <div className="aboutimgshape">
                         <LazyLoad>  <img src='https://webapi.entab.info/api/image/LFIS/public/Images/about-3.png' className="img-fluid"/></LazyLoad>
                         </div>
                </div>
             </div>
        </div>
     </div>
</div>
<div class="midsection"></div>
    <div class="midsectionbottom">
      <div class="container-xl">
         <div class="midsec wow animate__animated animate__slideInUp">
            <MidSecInfo></MidSecInfo>
          </div>
      </div>
    </div>
      <HomeNews></HomeNews>
      <div className="counters">
          <div className="container-fluid">
                <div className="row  wow animate__animated animate__slideInUp">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 countersbox">
                          <div className="counting"><h4 className="stat-number">25</h4><span></span></div>
                          <p>ClassName Size</p> 
                      </div> 
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 countersbox">
                          <div className="counting"><h4 className="stat-number">8</h4><span>:1</span></div>
                          <p>Student to Teacher Ratio</p> 
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 countersbox">
                          <div className="counting"><h4 className="stat-number">2300</h4><span>+</span></div>
                          <p>No. of Students</p>
                      </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 countersbox">
                            <div className="counting"><h4 className="stat-number">100</h4><span>%</span></div>
                            <p>Parents Satisfaction</p>
                        </div>
                </div>
          </div>
      </div>
      <div className="detailssec">
        <div className="container-xl">
            <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 details_sec wow animate__animated animate__fadeInLeft">
                  <div className="detailbox">
                          <div className="detailboximg">
                          <LazyLoad>   <img src='https://webapi.entab.info/api/image/LFIS/public/Images/detailimg1.png' className="img-fluid" alt="Lance Fort International School "/></LazyLoad>
                          </div>
                          <div className="detailboxdesc"> 
                            <h5>Visit School</h5>
                            <p>25 Feet Rd, Sunil Colony, Shankarpura, Burari, Delhi, 110084</p>
                          </div>
                  </div>
                  <div className="detailsbtn">
                    <Link to="https://maps.app.goo.gl/gaxjPWoFMoA7USmC9" target="_blank" className="mainbtnw">Google Map <span><i className="bi bi-geo-alt-fill"></i></span></Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 details_sec wow animate__animated animate__slideInDown">
                  <div className="detailbox">
                          <div className="detailboximg">
                          <LazyLoad>   <img src='https://webapi.entab.info/api/image/LFIS/public/Images/detailimg2.png' className="img-fluid" alt="Lance Fort International School "/></LazyLoad>
                          </div>
                          <div className="detailboxdesc"> 
                            <h5>inquire</h5>
                            <p>Need help with more information? We are just a phone call away.</p>
                          </div>
                  </div>
                  <div className="detailsbtn">
                    <Link to="tel:7303799381" className="mainbtnw">7303799381 <span><i className="bi bi-telephone-fill"></i></span></Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 details_sec wow animate__animated animate__fadeInRight">
                  <div className="detailbox">
                          <div className="detailboximg"> 
                          <LazyLoad>  <img src='https://webapi.entab.info/api/image/LFIS/public/Images/detailimg3.png' className="img-fluid" alt="Lance Fort International School "/></LazyLoad>
                          </div>
                          <div className="detailboxdesc"> 
                            <h5>ONLINE APPLICATION</h5>
                            <p>Our admission process is completely online and paper free</p>
                          </div>
                  </div>
                  <div className="detailsbtn">
                    <Link to="#" className="mainbtnw">Register Online <span><i className="bi bi-person-badge-fill"></i></span></Link>
                  </div>
                </div>
            </div>
        </div>
    </div>
      <div class="skillingsec">
      <h5 class="mainheading">Skilling right today, <b> for a better tomorrow</b></h5>
      <div class="container-fluid">
      <div className="skillingcircle"><SkilingInfo></SkilingInfo></div>
       <SkillingSlider></SkillingSlider>
       </div>
       </div>
      <HomeGallery></HomeGallery>
     </>
  )
}

export default Home
