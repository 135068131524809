import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async () => {
    const { data } = await axios.get(API_URL + `/api/highlight/LFIS`);
    console.log(data)
    return data;
}

export const getNews = async () => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/LFIS`);
    console.log("news:", data)
    return data;
}

export const getEvents = async () => {
    const { data } = await axios.get(API_URL + `/api/events/LFIS`);
    console.log(data)
    return data;
}

export const getEventsinner = async () => {
    const { data } = await axios.get(API_URL + `/api/events/All/LFIS`);
    console.log(data)
    return data;
}

export const getGallery = async () => {
    const { data } = await axios.get(API_URL + `/api/gallery/LFIS`);
    return data;
}

export const getGalleryInner = async () => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/LFIS`);
    return data;
}

export const getSubGallery = async (id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
}

export const getNotification = async () => {
    const { data } = await axios.get(API_URL + `/api/notification/LFIS`, getHeader());
    return data;
}

export const getTopper = async () => {
    const { data } = await axios.get(API_URL + `/api/topper/LFIS`, getHeader());
    return data;
}

export const getOnlineRegistration = async () => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/LFIS`, getHeader());
    return data;
}

export const getSliders = async () => {
    const { data } = await axios.get(API_URL + `/api/slider/LFIS`, getHeader());
    return data;
}

export const getPrincipalMessage = async () => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/LFIS`, getHeader());
    return data;
}

export const getMedia = async () => {
    const { data } = await axios.get(API_URL + `/api/media/LFIS`, getHeader());
    return data;
}
export const getVideoGallery = async () => {
    const { data } = await axios.get(API_URL + `/api/video/all/LFIS`, getHeader());
    return data;
}

export const getform = async () => {
    const { data } = await axios.get(API_URL + `/api/form/LFIS`, getHeader());
    return data;
}

export const postTransferCertificate = async (name, admission) => {
    console.log(name, admission)

    const { data } = await axios.post(API_URL + `/api/tc/LFIS`, { name, admission }, getHeader());
    return data;
}

export const form = async (formData, header, type) => {
    
    const { data } = await axios.post(API_URL + `/api/form/${type}`, formData, header);
    return data;
}