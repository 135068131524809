import { useEffect, useState } from "react";
import { getNews } from "../Service/Api";
// import article1 from'../Assets/Images/article1.jpg'
// import article2 from'../Assets/Images/article2.jpg'
// import article3 from'../Assets/Images/article3.jpg'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const NewsArticle = () => {

  // const navigate = useNavigate() 

    const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [year, setYear] = useState([]);

  
  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getNews();
      setData(galleryData);
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => new Date(item.fromDate).getFullYear()))
      );
      uniqueYears.sort((a, b) => b - a);
      setYear(uniqueYears);
      console.log(galleryData);
      setFilteredData(galleryData); // Initialize filteredData with all data
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter data based on selected year
    if (selectedYear && selectedYear !== "All") {
      const filteredData = data.filter((item) => {
        const itemYear = new Date(item.fromDate).getFullYear();
        return itemYear === parseInt(selectedYear);
      });
      setFilteredData(filteredData);
    } else {
      // If selected year is "All" or not selected, set filterData to all data
      setFilteredData(data);
    }
  }, [selectedYear, data]); // Added 'data' as a dependency
  ; // Added 'data' as a dependency

  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleMonth = (month) => {
    return monthsOfYear[month - 1];
  };

  

  return (
     <>
     <div class="innerslide">
          <h3>News & Article</h3>
          <ul class="braedcrumb">
              <li><Link to="/">Home</Link></li>
              <li>News & Article</li>
          </ul>
      </div>
          <div className="innersec">
          <div className="container">
              <div className="row tabs-dynamic">
                      <div className="col-md-3">
                          <div className="count-val">
                              <p>Total Count:{filterData.length} </p>
                          </div>
                      </div>
                      <div className="col-md-3">
                          <div className="month-selection">
                          <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value={"All"}>All</option>
                  {year.map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                  {/* <option value={2022}>2022</option>
                  <option value={2021}>2021</option>
                  <option value={2020}>2020</option>
                  <option value={2019}>2019</option>
                  <option value={2018}>2018</option> */}
                  {/* Add more years if necessary */}
                </select>

                          </div>
                      </div>
                      <div className="col-md-3">
                          <div className="month-selection">
                          <select className="monthSelect">
                            <option value="0">All Month</option>

                        </select>
                          </div>
                      </div>
                      <div className="col-md-3">
                          <div className="searchBox">
                          <input type="search" id="myInput" name="name" placeholder="Search Here.."/>
                          </div>
                      </div>
                  </div>
                  <div className="row years year2024 showYear">

                  {filterData.length > 0 &&
              filterData.map((item, index) => (
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount ">
                          <div className="newsbox">
                            <div className="newsboximg"> 
                            <LazyLoad>       <SlideshowLightbox><img src={`https://webapi.entab.info/api/image/${item?.attachments}`} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox></LazyLoad>
                            </div><div className="newsboxdesc">
                                <div className="newspgh">
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                                </div>
                                <div className="newsdate">
                                  <span>{item.date}</span>
                                 <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                                </div>
                            </div>
                        </div>
                      </div>
                       ))} 
                    
                       
                  </div> 
          </div>
      </div>
     </>
  )
}

export default NewsArticle
