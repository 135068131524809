import React from 'react'
import LazyLoad from 'react-lazyload';

const MidSecInfoprop = (props) => {
  return (
    <>
    <div className="midsecbox">
      <div className="midsecboximg">
      <LazyLoad>    <img src={props.image} alt="Lance Fort International School" /> </LazyLoad>
        </div>
       <p>{props.title}</p>
    </div>
    </>
  )
}

export default MidSecInfoprop
