import React, { useEffect, useState } from "react";

import { getVideoGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
const VideoGallery = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const getData = async () => {
        const datas = await getVideoGallery();
        setData(datas);
      };
      getData();
    }, []);
  
    useEffect(() => {
      console.log("events_data", data);
    }, [data]);
  
    const emptyArray = [
      { title: "Video Title", date: "DD/MM/YYYY" },
      { title: "Video Title", date: "DD/MM/YYYY" },
      { title: "Video Title", date: "DD/MM/YYYY" },
    ];
    return (
        <>
            <div className="innerslide">
                <h3>Video Gallery</h3>
                <ul className="braedcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Gallery</li>
                    <li>Video Gallery</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            

                                <div className="clr"></div>
                                <div className="videocontainer">
                                    {data &&
                                        data.length > 0 &&
                                        data.map((item, index) => (
                                            <div className="videodetails"  key={index}>
                                                <Link to={item.videoUrl} target="_blank">
                                                    <i className="play-circle-fill"></i>
                                                    <div className="videodesc">
                                                        <h3>{item.title}</h3>
                                                        <span>{item.date}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoGallery
