import React from 'react'
import { Link } from 'react-router-dom'
const EarlyLearningAges = () => {
    return (
        <>
            <div className="innerslide">
                <h3>Early Learning | Age 3-5</h3>
                <ul className="braedcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Learning</li>
                    <li>Early Learning | Age 3-5</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h4 className="grdxt">Educating For Life In The Heart Of Lance Fort</h4>
                            <p><b>Lance Fort International School (LFIS) </b> where Early Learning is based on Educational Neuro-Science (EN) has long provided children with engaging and meaningful learning experiences that form the foundation for academic success. We offer a Kindergarten programme in our Early Learning Centre, which fosters in each child a love of learning and sense of self through an inquiry-based curriculum. We emphasise play and discovery both in- and outdoors, which sparks a sense of wonder and curiosity. </p>

                            <p>our early learning programme develops the whole child. The learning experience in our Early Learning Centre is focused upon providing a safe, nurturing environment in which your child can explore the early stages of the various literacies <b>(reading, writing, mathematical)</b> that will be key to all of their future learning. Our young learners acquire the skills necessary for thriving in Primary School, whether they continue here, in their home country, or abroad.</p>

                            <p>Our programme is enriched by visits to our Garden of Wonders & Library, Loca & National excursions, playtime in their private playground, and attendance at and active participation in whole school activities and school productions.</p>
                            <p>To learn how <b>LFIS</b> can benefit your family, please explore the<b> ‘Early Learning Centre in Depth’</b> section below or <b><span>contact our admissions </span></b> team for a personal consultation.</p>

                            <h5 className="mid-head">Early Learning Centre In Depth </h5>
                            <p className="smallhead">Curriculum</p>
                            <p><b>LFIS</b> is  committed to the rigorously framed & deisgned Lance Fort Research & Development based Primary Years Programme <b> (PYP)</b> Curriculum, meaning that our teaching is balanced, concept-based, and planned around our students. The Early Learning Centre Programme focuses on these pillars of learning.</p>
                            <ul className="learningli">
                                <li>  <b>Play:</b> The daily schedule is built around opportunities for adult-supported, freely chosen play and adult-led, small group activities.  </li>
                                <li>  Outdoor Learning and Physical Development: The children participate in daily outdoor learning sessions, movement classes twice per week. In addition, students have  skating lessons each Friday as part of our school's Skate Fridays' programme. </li>
                                <li>  <b>Literacy Development:</b> Our teachers understand the vital role of literacy in childhood development. They focus on encouraging children's enjoyment of reading alongside their developing interest in signs, symbols, letters, and writing. Children visit our Library each week to support their learning and have access to our media collection in multiple languages.  </li>
                                <li>  <b>Multilingualism: </b>We appreciate the importance of language development and, when possible, provide opportunities for children to use their language of choice. For children from non-English speaking backgrounds, we offer English as an additional language support.  </li>
                                <li> <b> Arts and Music:</b> Children have a weekly music class using an array of different instruments and are supported by the Music Teacher. In addition, all of our learning spaces emphasize the creative arts and have well-equipped art areas.  </li>
                                <li>  <b>Mathematical Development: </b> Children are given multiple opportunities for reasoning and problem-solving, supported by their teachers. </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EarlyLearningAges
