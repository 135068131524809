import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <div className="footerbottom">
        <div className="container">
            <div className="row">
            <div className="col-xl-3 col-lg-6 footercontainerfst">
                <h4>Lance Fort International School</h4>
                <p>Renowned for quality education and extra-curricular activities, Lance Fort is among the top co-ed senior secondary schools in Delhi producing geniuses of tomorrow.</p>
                
            </div>
            <div className="col-xl-3 col-lg-6">
                <h4>our links</h4>
                <ul>
                    <li><Link to="#">About Us</Link></li>
                    <li><Link to="Gallery">Gallery</Link></li>
                    <li><Link to="AdmissionProcess">Admission Process</Link></li>
                    <li><Link to="SchoolErpSoftware">ERP Software</Link></li>
                </ul>
            </div>
            <div className="col-xl-3 col-lg-6">
                <h4>Leaning</h4>
                <ul>
                    <li><Link to="#">Learning & Enrichment</Link></li>
                    <li><Link to="EarlyLearningAges">Early Learning | Age 3-5</Link></li>
                    <li><Link to="PrimaryAges">Primary Leaning | Age 6-11</Link></li>
                    <li><Link to="MiddleAges">Middle | Ages 11-14</Link></li>
                </ul>
            </div>
            <div className="col-xl-3 col-lg-6">
                <h4>Get In Touch</h4>
                <p>If you want to inquire about the admission process, feel free to contact us. Our team members will clear all your doubts and will offer the right assistance.</p>
            </div>
        </div>
        </div>
        <div className="copyright">
            <p>© Copyright 2024. All rights reserved by Lance Fort International School. Designed & developed by  <Link className="link" to="https://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </Link></p>
        </div>
    </div>
    </>
  )
}

export default Footer
