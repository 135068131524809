import React from 'react'
import SkilingInfoprops from'../Components/SkilingInfoprops' 
const SkilingInfo = () => {
  return (
    <>
       <SkilingInfoprops title="Fine Arts" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling1.png"/>
       <SkilingInfoprops title="Athletics" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling2.png"/>
       <SkilingInfoprops title="Sports" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling3.png"/>
       <SkilingInfoprops title="Resource Room" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling4.png"/>
       <SkilingInfoprops title="Dining Hall" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling5.png"/>
       <SkilingInfoprops title="Summer Camp Programs" image="https://webapi.entab.info/api/image/LFIS/public/Images/skilling6.png"/>
    </>
  )
}

export default SkilingInfo
