import React, { useEffect, useState } from 'react'
import { getOnlineRegistration } from '../Service/Api';

function OnlineReg() {

    
  const [data, setData] = useState([]);

  useEffect(()=>{
    const getData =async ()=>{
      try {
        let registrationData = await getOnlineRegistration()
        setData(registrationData);
        
      } catch (error) {
        console.log(error)
      }
    }

    getData()
  },[])


  useEffect(() => {
    console.log("Online reg", data);
}, [data]);


  return (
    

    <>
     <span style={{display:data.length>0?`block`:'none'}} className="bounce1">
      <a href="https://lancefort.campuscare.cloud" target="_blank" class="parantlogo "> {data[0]?.title} </a>
          <a href={`https://webapi.entab.info/api/image/${data[0]?.attachments[0]}`} target="_blank" class=""> <i class="bi bi-paperclip"></i></a>
          </span>
    </>


  )
}

export default OnlineReg